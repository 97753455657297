<template>
  <div>
    <!-- 模型加载loading -->
    <!-- <model-status v-if="modelLoading || modelInitializing" :modelLoading="modelLoading"
      :modelInitializing="modelInitializing"></model-status> -->
    <div class="back-ground-remover-wrap" :style="{ 'padding-top': getPadding }">
      <p class="tool-title" :style="{ 'margin-bottom': getMarginBottom }">
        Remover Cloth BackGround
      </p>
      <!-- 上传组件 -->
      <div class="common-upload-box" ref="drag">
        <common-upload @changFileList="changFileList" @changSelectImage="changSelectImage" :imageUrls="imageUrls"
          :modelLoading="modelLoading" :modelInitializing="modelInitializing" :modelLoadingError="modelLoadingError"
          :webcamEnabled="webcamEnabled" :modelInProgress="modelInProgress" :imageHandleComplete="imageHandleComplete"
          :changeImage="changeImage" :percentSpeedTime="2000" />
      </div>

      <!-- 图片展示区域 -->
      <div class="backGroundRemover-container" :style="{
        opacity: imageHandleComplete ? 1 : 0,
        'margin-top': getMarginTop,
      }">
        <!-- 原图展示区域 -->
        <div class="origin-container container">
          <p class="result-title">Original</p>
          <div class="image-box" style="width: 412px; height: 412px;">
            <image-board :srcData="rawImage" />
          </div>
        </div>

        <!-- 抠图展示区域 -->
        <div class="result-container container">
          <p class="result-title">Removed Background</p>
          <div class="position-box" @mouseenter="handleMattingBoxMouseEnter" @mouseleave="handleMattingBoxMouseLeave">
            <div class="image-box result-image-box" style="width: 412px; height: 412px;">
              <image-board ref="resultBoardRef" :srcData="resultImage" :isModelDone="isModelDone"
                @drawDown="drawDown" />
            </div>
            <div class="buttons-container" v-if="showMattingEditBtns">
              <a-button type="default" @click="handleOpenMattingModal" class="ant-btn-default">Edit</a-button>
              <a-button type="primary" @click="handleMattingDownload">Download</a-button>
            </div>
          </div>
          <common-rate @changeRate="handleMattingChangeRate" :clearRate="clearRate" />
        </div>

        <!-- 背景替换区域 -->
        <div class="add-container container">
          <p class="result-title">Add Design</p>
          <div class="image-box add-design-container" style="width: 412px; height: 412px;" v-if="!showAddDesignResult">
            <div class="upload-box">
              <a-upload-dragger v-model="bgFileList" name="file" :multiple="false" :customRequest="customBgUpload"
                :showUploadList="false" @change="handleReplaceBgChange">
                <p class="ant-upload-text">Click or drop a background here</p>
              </a-upload-dragger>
            </div>
            <div class="add-design-tip">
              <p>or</p>
              <p>try one of these</p>
            </div>
            <div class="bg-items">
              <img v-for="item in defaultBgImages" :key="item.id" :src="item.url" class="bg-item"
                @click="handleSelectBg(item)" />
            </div>
          </div>

          <div class="position-box" @mouseenter="handleReplaceBgBoxMouseEnter"
            @mouseleave="handleReplaceBgBoxMouseLeave">
            <div v-show="showAddDesignResult" class="image-box" style="width: 412px; height: 412px;">
              <image-board ref="bgBoardRef" :srcData="resultImage" :bgData="bgImage" />
            </div>

            <div class="buttons-container" v-if="showEditReplaceBgBtns">
              <a-button @click="handleOpenReplaceBgModal" class="ant-btn-default">Edit</a-button>
              <a-button type="primary" @click="handleBgReplaceDownload">Download</a-button>
            </div>
          </div>
          <common-rate @changeRate="handleReplaceBgChangeRate" v-if="currentBgItem" :clearRate="clearRate" />
        </div>
      </div>
    </div>
    <div>

    </div>
    <!-- 擦除编辑弹窗 -->
    <matting-edit-modal :isShowMattingModal="isShowMattingModal" :rawImage="rawImage" :resultImage="resultImage"
      @closeModal="handleMattingModalClose" @savaMattingImage="handleSavaMattingImage" />

    <!-- 替换背景弹窗 -->
    <bg-replace-modal :isShowReplaceBgModal="isShowReplaceBgModal" @closeModal="handleCloseReplaceBgModal"
      :resultImageData="resultImage" :currentBgItem="currentBgItem" @confirmReplaceBg="handleConfirmReplaceBg" />
  </div>
</template>

<script setup>
import loadImage from "blueimp-load-image";
import ModelStatus from "@/components/modelStatus.vue";
import { runModelUtils } from "@/utils";
import { onMounted, ref, watch, nextTick, defineProps, computed, watchEffect } from "vue";
import CommonUpload from "@/components/commonUpload.vue";
import MattingEditModal from "@/components/matting-edit-modal";
import BgReplaceModal from "@/components/bg-replace-modal";
import CommonRate from "@/components/common-rate.vue";
import dataUtils from "@/utils/util.js";
import { postCanvasToURL } from "@/utils/buriedFunction/getWebpFromCanvas";
import { uploadBackground } from '@/utils/buriedFunction/uploadBackground'
import ImageBoard from '@/components/image-board';
import { isString } from 'lodash';
import iconClose1 from '@/assets/icon_close_1.png'
import iconClose2 from '@/assets/icon_close_2.png'
import iconClose3 from '@/assets/icon_close_3.png'
import removeClose1 from '@/assets/remove_close_1.png'
import { bgsList } from './data.js'

const props = defineProps({
  modelFilepath: String,
  imageSize: Object,
  warmupModel: Function,
  preProcess: Function,
  postProcess: Function,
  resizeSize: Object,
});
const imageUrls = ref([

  {
    id: 1,
    iconSrc: iconClose1,
    src: removeClose1,
  },
  {
    id: 2,
    iconSrc: iconClose2,
    src: iconClose2,
  },
  {
    id: 3,
    iconSrc: iconClose3,
    src: iconClose3,
  },
])
const inferenceTime = ref(null);
const session = ref(null);
const gpuSession = ref(null);
const cpuSession = ref(null);
const modelLoading = ref(true);
const modelInitializing = ref(true);
const sessionRunning = ref(false);
const imageURLInput = ref("");
const modelInProgress = ref('start');
const imageHandleComplete = ref(false);
const imageLoadingError = ref(false);
const modelLoadingError = ref(false);
const webcamEnabled = ref(false);
const sessionBackend = ref("webgl");
const modelFile = ref(new ArrayBuffer(0));
const currentFileList = ref([]);
const bgFileList = ref([]);
const showAddDesignResult = ref(false);
const showMattingEditBtns = ref(false);
const changeImage = ref(false);

const rawImage = ref(null);
const resultImage = ref(null);
const bgImage = ref(null);
const resultBoardRef = ref(null);
const bgBoardRef = ref(null);

const isShowMattingModal = ref(false);
const isShowReplaceBgModal = ref(false);
const showEditReplaceBgBtns = ref(false);
const currentMattingSelectRate = ref({});
const currentReplaceBgSelectRate = ref({});
const clientHeight = ref(0);
const drag = ref(null);
const currentBgItem = ref(null);
const clearRate = ref(false)
const proxyUrl = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_API;
const modalData = ref(null)
const isModelDone = ref(false)
const defaultBgImages = ref([
  {
    id: 4,
    url: proxyUrl + '/static/ai_tools/backgrounds/3VuFEc65rCemIaqll97ISd.webp'
  }, {
    id: 5,
    url: proxyUrl + '/static/ai_tools/backgrounds/5DpjreDULpthMF0Dd5aRq6.webp'
  }, {
    id: 6,
    url: proxyUrl + '/static/ai_tools/backgrounds/5uPVGaOp0ehIggt8w2z7Fe.webp'
  }
]);

// 背景图预加载
const preLoadImageList = () => {
  bgsList?.forEach(item => {
    let img = new Image()
    img.src = item.url
    img.onload = () => {
      console.log('背景图预加载')
    }
  })
}

const getClientHeight = () => {
  const height = document.documentElement.clientHeight;
  clientHeight.value = height;
};

const getPadding = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "18px";
  } else if (imageHandleComplete.value && clientHeight.value > 789 && clientHeight.value <= 900) {
    return "48px";
  } else if (imageHandleComplete.value && clientHeight.value > 900) {
    return "80px";
  } else {
    return "180px";
  }
});

const getMarginBottom = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "30px";
  } else {
    return "82px";
  }
});

const getMarginTop = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "30px";
  } else if (imageHandleComplete.value && clientHeight.value > 789) {
    return "82px";
  } else {
    return "-200px";
  }
});

// 监听窗口变化，调整间距
window.onresize = () => {
  getClientHeight();
};

// 模型初始化
const initSession = async () => {

  sessionRunning.value = false;
  modelLoadingError.value = false;
  if (sessionBackend.value === "webgl") {
    if (gpuSession.value) {
      session.value = gpuSession.value;
      return;
    }
    // modelLoading.value = true;
    // modelInitializing.value = true;
  }
  if (sessionBackend.value === "wasm") {
    if (cpuSession.value) {
      session.value = cpuSession.value;
      return;
    }
    // modelLoading.value = true;
    // modelInitializing.value = true;
  }

  try {
    if (sessionBackend.value === "webgl") {
      gpuSession.value = await runModelUtils.createModelGpu(modelFile.value);
      session.value = gpuSession.value;
    } else if (sessionBackend.value === "wasm") {
      cpuSession.value = await runModelUtils.createModelCpu(modelFile.value);
      session.value = cpuSession.value;
    }
  } catch (e) {
    modelLoading.value = false;
    modelInitializing.value = false;
    if (sessionBackend.value === "webgl") {
      gpuSession.value = undefined;
    } else {
      cpuSession.value = undefined;
    }
    throw new Error("Error: Backend not supported. ");
  }
  modelLoading.value = false;

  if (sessionBackend.value === "webgl") {
    setTimeout(() => {
      props.warmupModel(session.value);
      modelInitializing.value = false;
    }, 0);
  } else {
    await props.warmupModel(session.value);
    modelInitializing.value = false;
  }
};

onMounted(async () => {
  // 加载模型，并初始化
  const response = await fetch(props.modelFilepath);
  modelFile.value = await response.arrayBuffer();
  preLoadImageList()
  try {
    await initSession();
  } catch (e) {
    sessionBackend.value = "wasm";
  }

});

// 切换处理模式
const onSessionBackendChange = async (newVal) => {
  sessionBackend.value = newVal;
  clearCanvas();
  try {
    await initSession();
  } catch (e) {
    modelLoadingError.value = true;
  }
  return newVal;
};

watch(sessionBackend, (newVal) => {
  onSessionBackendChange(newVal);
});

// 文件上传
const changFileList = (fileList) => {
  clearRate.value = true
  if (!fileList || !fileList.length) {
    return;
  }

  clearCanvas();
  currentFileList.value = fileList;

  const file = fileList[0].originFileObj;

  modelInProgress.value = 'progressing';
  if (imageHandleComplete.value) {
    changeImage.value = true;
    imageHandleComplete.value = false;
  }
  const url = URL.createObjectURL(file);
  loadImageToCanvas(url);
};

// 点击选择图片
const changSelectImage = (url) => {
  clearCanvas();
  loadImageToCanvas(url);
};

watchEffect(() => {
  if (session.value && modalData.value) {
    console.log('session.value', session.value, modalData.value)
    runModel(modalData.value.tempCtx, modalData.value.img);
  }
})

// 上传之后canvas 绘制图片
const loadImageToCanvas = (url) => {
  modelInProgress.value = 'progressing';
  if (!url) {
    rawImage.value = null;
    resultImage.value = null;
    return;
  }
  loadImage(
    url,
    async (img) => {
      if (img.type === "error") {
        imageLoadingError.value = true;
        // modelInProgress.value = false;
      } else {
        imageLoadingError.value = false;
        sessionRunning.value = true;
        inferenceTime.value = 0;
        const tempCtx = dataUtils.createCanvasCtx(img)
        tempCtx.drawImage(img, 0, 0)
        const imageData = tempCtx.getImageData(0, 0, img.width, img.height)
        rawImage.value = imageData;
        getClientHeight();
        modalData.value = {
          tempCtx,
          img
        }
        // runModel(tempCtx, img);
      }
    },
    {
      cover: true,
      crop: true,
      canvas: true,
      crossOrigin: "Anonymous",
    }
  );
};

const runModel = async (tempCtx, img) => {
  // modelInProgress.value = true;
  sessionRunning.value = true;
  const { tensor, resizeSize, resizeData } = await props.preProcess(
    tempCtx,
    img,
  );
  let outputTensor;
  [outputTensor] = await runModelUtils.runModel(
    session.value,
    tensor
  );

  // 绘制抠图之后的图片
  props.postProcess(outputTensor, resizeData, resizeSize).then(async res => {
    // 缩放imageData
    const resizeImagedata = await dataUtils.resizeImageData(res, img.width, img.height)
    imageHandleComplete.value = true;
    resultImage.value = resizeImagedata
    // modelInProgress.value = false;
    isModelDone.value = true
  });
  sessionRunning.value = false;

};

const drawDown = (value) => {
  if (value) {
    modelInProgress.value = 'done';
  }
}

// 背景替换
const handleReplaceBgChange = (value) => {
  bgFileList.value = value.fileList;
  const file = value.fileList[0].originFileObj;
  currentBgItem.value = file;
  //上报用户上传的背景图
  uploadBackground(file);
  showAddDesignResult.value = true;
  drawBgImage(file);
};

/** 获取指定链接下的位图图像 */
async function getLoadedImage (picFile) {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = (isString(picFile) ? picFile : URL.createObjectURL(picFile))
  await new Promise((resolve) => {
    img.onload = () => resolve();
  });
  const canvas = dataUtils.createCanvasCtx(img);
  canvas.drawImage(img, 0, 0);
  return canvas.getImageData(0, 0, img.width, img.height);
}

// 绘制背景图
const drawBgImage = async (file) => {
  if (!file) {
    return;
  }
  bgImage.value = await getLoadedImage(file);
}

// 背景上传
const customBgUpload = () => {
  console.log("上传背景");
};

// 选择背景
const handleSelectBg = async (item) => {
  showAddDesignResult.value = true;
  currentBgItem.value = item;
  await drawBgImage(item.url, resultImage.value);
};

const resizeBg = (bgImage, canvas) => {
  // 如果背景图宽高都小于canvas, canvas自动填充铺满
  const bgWidth = bgImage.width
  const bgHeight = bgImage.height
  const canvasWidth = canvas.width
  const canvasHeight = canvas.height

  if ((bgWidth < canvasWidth && bgHeight < canvasHeight) || (bgWidth == canvasWidth && bgHeight == canvasHeight)) {
    return {
      sx: 0,
      sy: 0,
      sw: canvasWidth,
      sh: canvasHeight,
      dx: 0,
      dy: 0,
      dw: canvasWidth,
      dh: canvasHeight
    }
  }
  // const bgW
  // 如果单边小于canvas, 那就缩放，将多余的裁切
  const ratioWidth = canvasWidth / bgWidth;
  const ratioHeight = canvasHeight / bgHeight;
  if (bgWidth < canvasWidth && bgHeight > canvasHeight) {
    return {
      multiple: canvasWidth / bgWidth,
      sx: 0,
      sy: (bgHeight - canvasHeight / ratioWidth) / 2,
      sw: canvasWidth,
      sh: canvasHeight / ratioWidth,
      dx: 0,
      dy: 0,
      dw: canvasWidth,
      dh: canvasHeight,
    }
  }

  if (bgWidth > canvasWidth && bgHeight < canvasHeight) {
    return {
      multiple: canvasHeight / bgHeight,
      sx: (bgWidth - canvasWidth / ratioHeight) / 2,
      sy: 0,
      sw: canvasWidth / ratioHeight,
      sh: bgWidth,
      dx: 0,
      dy: 0,
      dw: canvasWidth,
      dh: canvasHeight
    }
  }

  if (bgWidth > canvasWidth && bgHeight > canvasHeight) {

    if (ratioWidth < ratioHeight) {
      return {
        multiple: canvasHeight / bgHeight,
        sx: 0,
        sy: (bgHeight - canvasHeight / ratioWidth) / 2,
        sw: canvasWidth / ratioHeight,
        sh: bgWidth,
        dx: 0,
        dy: 0,
        dw: canvasWidth,
        dh: canvasHeight
      }
    }

    return {
      multiple: canvasHeight / bgHeight,
      sx: (bgWidth - canvasWidth / ratioHeight) / 2,
      sy: 0,
      sw: canvasWidth / ratioHeight,
      sh: bgHeight,
      dx: 0,
      dy: 0,
      dw: canvasWidth,
      dh: canvasHeight
    }
  }
}

// 按钮的显示隐藏
const handleMattingBoxMouseEnter = () => {
  showMattingEditBtns.value = true;
};

const handleMattingBoxMouseLeave = () => {
  showMattingEditBtns.value = false;
};

// 打开擦除弹窗
const handleOpenMattingModal = () => {
  isShowMattingModal.value = true;
};

// 关闭擦除弹窗
const handleMattingModalClose = () => {
  isShowMattingModal.value = false;
};

const handleSavaMattingImage = (imageData) => {
  postCanvasToURL(bgBoardRef.value.getImageCanvas(), "remove-cloth-background-done")
  resultImage.value = imageData;
};

// 打开背景替换弹窗
const handleOpenReplaceBgModal = () => {
  isShowReplaceBgModal.value = true;
};
// 关闭背景替换弹窗
const handleCloseReplaceBgModal = () => {
  isShowReplaceBgModal.value = false;
};

// 按钮显隐
const handleReplaceBgBoxMouseEnter = () => {
  showEditReplaceBgBtns.value = true;
};

const handleReplaceBgBoxMouseLeave = () => {
  showEditReplaceBgBtns.value = false;
};

const handleMattingChangeRate = (item) => {
  //上报emoji 
  postCanvasToURL(resultBoardRef.value.getImageCanvas(), "remove-cloth-background-emoji", item)
  currentMattingSelectRate.value = item;
};

const handleReplaceBgChangeRate = (item) => {
  //上报emoji
  const params = {
    id: item.id,
    imageId: currentBgItem.value.id
  }
  postCanvasToURL(bgBoardRef.value.getImageCanvas(), "add-cloth-design-emoji", params)
  currentReplaceBgSelectRate.value = item;
};

// 确认背景替换
const handleConfirmReplaceBg = (item) => {
  //上报替换背景
  postCanvasToURL(bgBoardRef.value.getImageCanvas(), "add-cloth-design-done", item.id)
  showAddDesignResult.value = true;
  currentBgItem.value = item;
  const file = item.url ? item.url : item
  drawBgImage(file);
};

// 替换背景下载
const handleBgReplaceDownload = () => {
  new Promise(async (resolve) => {
    const imageData = resultImage.value;
    const canvas = document.createElement('canvas')
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    const ctx = canvas.getContext('2d');
    const bgBitmap = await createImageBitmap(bgImage.value);
    ctx.drawImage(bgBitmap, 0, 0, imageData.width, imageData.height);
    const resultBitmap = await createImageBitmap(resultImage.value);
    ctx.drawImage(resultBitmap, 0, 0);
    postCanvasToURL(canvas, "add-cloth-design-download", currentBgItem.value.id);
    dataUtils.downloadFn(canvas);
    resolve();
  }).catch(() => {
  });
};

// 抠图下载
const handleMattingDownload = () => {
  const canvas = getCanvasFromImage(resultImage.value);
  postCanvasToURL(canvas, "remove-cloth-background-download", {});
  dataUtils.downloadFn(canvas);
};

const getCanvasFromImage = (imageData) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  ctx.putImageData(imageData, 0, 0);
  return canvas;
}

// 清除画布
const clearCanvas = () => {
  inferenceTime.value = 0;
  imageURLInput.value = "";
  modelInProgress.value = 'start';
  imageLoadingError.value = false;
  showAddDesignResult.value = false;
  resultImage.value = null;
  rawImage.value = null;
  currentFileList.value = [];
  // dataUtils.clearCanvas('input-canvas')
  dataUtils.clearCanvas('output-canvas')
  dataUtils.clearCanvas('add-canvas')
};
</script>

<style lang="less" scoped>
.back-ground-remover-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backGroundRemover-container {
  width: 100%;
  // height: 452px;

  display: flex;
  justify-content: center;
}

.container {
  margin-right: 30px;
}

.tool-title {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}

.common-upload-box {
  width: 604px;
  height: 92px;
  border: 2px dashed #878787;
  border-radius: 6px;
}

.result-title {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #000000;
}

.position-box {
  position: relative;

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 80px 14px 80px;
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    animation: fadenum 0.5s 1;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.image-box {
  width: 412px;
  height: 412px;
  background-color: #f7f7f7;
  position: relative;
}

.result-image-box {
  position: relative;

  .canvas-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.draw-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.webcam-panel {
  padding: 40px 20px;
  margin-top: 30px;
  background-color: white;
  position: relative;
}

.webcam-container {
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 0 auto;
  width: 412px;
  height: 412px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & :nth-child(n + 3) {
    position: absolute;
    font-size: 24px;

    & :first-child {
      background: white;
      color: black;
      opacity: 0.8;
      font-size: 12px;
      padding: 3px;
      text-transform: capitalize;
      white-space: nowrap;
    }
  }
}

.inputs {
  margin: auto;
  background: #f5f5f5;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  width: 100%;
  height: 38px;
  font-size: 14px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  justify-content: center;
  padding: 0 16px;
}

.inputs:focus,
.inputs:hover {
  position: relative;
  background: rgba(0, 0, 0, 0.12);
}

.backend-selector {
  width: 200px;
}

.upload-box {
  width: 245px;
}

.add-design-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 79px 84px;
}

:global(.add-design-container .ant-upload.ant-upload-drag .ant-upload) {
  width: 245px;
  height: 64px;
  border: 1px dashed #878787;
  border-radius: 6px;
  background: #f7f7f7;
  padding: 0;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;

  line-height: 20px;
  text-align: center;
  color: #969696;
  cursor: pointer;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  height: 100%;
  font-size: 14px;
  line-height: 64px;
  color: #969696;
}

:global(.add-design-container .ant-upload.ant-upload-drag) {
  border: none;
}

.add-design-tip {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #969696;
  margin-top: 50px;

  P {
    margin-bottom: 0;
  }
}

.bg-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.bg-item {
  width: 76px;
  height: 76px;
  background: #d9d9d9;
  border-radius: 4.14545px;
  cursor: pointer;
}

:global(.unreal-dragger.ant-upload.ant-upload-drag) {
  width: 412px;
  height: 412px;
  border: none;
  background: none;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  margin: 0;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover .ant-upload-text) {
  padding: 0;
  background-color: rgba(135, 94, 255, 0.12);
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover) {
  background-color: rgba(135, 94, 255, 0.12);
}
</style>
